const BLOG_TAG_COLORS_REGISTRY = {
  Interview: { background: "#B8EDFF", text: "#06354B" },
  "Micro-learning": { background: "#DFD8FC", text: "#311461" },
} as const satisfies Record<string, { background: string; text: string }>;

export function useBlogTagColors(tag: string = "Interview") {
  const match =
    BLOG_TAG_COLORS_REGISTRY[tag as keyof typeof BLOG_TAG_COLORS_REGISTRY];

  if (match) return match;

  const background = useStringToColor(tag ?? "", {
    saturation: 85,
    luminance: 85,
  });

  const text = useStringToColor(tag ?? "", {
    saturation: 90,
    luminance: 20,
  });

  return {
    background: background.value,
    text: text.value,
  };
}
